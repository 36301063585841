@import-normalize;

@font-face {
    font-family: 'Druk Cy';
    src: url('fonts/Druk-Medium-Web.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

* {
    box-sizing: border-box;
}
